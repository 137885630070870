import { ChangeEvent, useCallback, useState } from "react";

import { MAX_SUPPLY, mint, useCandyMachine } from "./services";
import trumpPepe from "./images/trump-pepe.png";

export function Mint() {
  // State
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [minted, setMinted] = useState(0);

  // Vars
  const { candyMachine, refetch, umi, wallet } = useCandyMachine();

  // Handlers
  const handleChangeAmount = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setError("");
      setAmount(ev.target.value);
    },
    []
  );

  const handleMint = useCallback(async () => {
    if (!amount) {
      setError("Please choose amount");
      return;
    }

    if (Number(amount) > 47) {
      setError("Cannot mint more than 47 NFTs");
      return;
    }

    if (!wallet.publicKey) {
      setError("Please connect wallet");
      return;
    }

    setError("");

    if (!candyMachine) {
      return;
    }

    setLoading(true);

    try {
      await mint(Number(amount), candyMachine, umi);

      console.log("success", `Mint successful!`);

      setMinted(Number(amount));

      refetch();
    } catch (err: any) {
      setError("An error occured. Please try again.");

      console.log("error", `Mint failed! ${err?.message}`);
    } finally {
      setLoading(false);
    }
  }, [amount, candyMachine, refetch, umi, wallet.publicKey]);

  const remaining = MAX_SUPPLY - Number(candyMachine?.itemsRedeemed ?? "0");
  const isMintedOut = remaining <= 0;

  return (
    <div className="mint-container">
      <div>
        <div className="mint-box">
          <div className="mint-box__inner">
            <div className="mint-box__data">
              <div>
                <h4 className="mint-box__title">Remaining</h4>
                <span className="mint-box__value">{remaining - minted}</span>
              </div>
              <div>
                <h4 className="mint-box__title">Price</h4>
                <span className="mint-box__value">0.147</span>
              </div>
              <div>
                <div className="live-indicator">LIVE</div>
              </div>
            </div>
            <div className="mint-box__button-container">
              <div>
                <button
                  className="button mint-box__button"
                  onClick={handleMint}
                  disabled={!candyMachine || isMintedOut}
                >
                  {isMintedOut
                    ? "Gone"
                    : isLoading
                      ? "Minting..."
                      : minted
                        ? "Minted !"
                        : "Mint"}
                </button>
              </div>
              {!isLoading && !minted && !isMintedOut && (
                <div>
                  <input
                    inputMode="numeric"
                    placeholder="0"
                    value={amount}
                    required
                    onChange={handleChangeAmount}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="col-8">
          {!!error && <small className="error">{error}</small>}
          <img src={trumpPepe} className="trump-pepe" alt="Trump PEPE" />
        </div>
      </div>
    </div>
  );
}
