import { publicKey } from "@metaplex-foundation/umi";
// import { clusterApiUrl } from "@solana/web3.js";

export const MAX_SUPPLY = 4747;

export const CANDY_MACHINE_ID = publicKey(
  "HnBVQQCELzvbVikR4prBLX15tUgMwh4wwpAHvW5AmfVV"
);

// export const RPC_ENDPOINT =
//   "https://solana-mainnet.g.alchemy.com/v2/eB1ujYq3tmAaO63U_0vFRB7Zu2CnHd1e" ||
//   clusterApiUrl("devnet");

// export const RPC_ENDPOINT =
//   "https://winter-smart-seed.solana-mainnet.quiknode.pro/14b18b96bc690cacbcd659ca5b691bb87cfa22d3/";

export const RPC_ENDPOINT =
  "https://solana-mainnet.g.alchemy.com/v2/eB1ujYq3tmAaO63U_0vFRB7Zu2CnHd1e";

// export const RPC_ENDPOINT = "https://api.mainnet-beta.solana.com";
