import { WalletError } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { FC, ReactNode, useCallback, useMemo } from "react";

// import { AutoConnectProvider, useAutoConnect } from "./auto-connect";
import { RPC_ENDPOINT } from "../services";

export const ContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // const { autoConnect } = useAutoConnect();

  const wallets = useMemo(() => [], []);

  const onError = useCallback((error: WalletError) => {
    console.error(error);
  }, []);

  return (
    // TODO: updates needed for updating and referencing endpoint: wallet adapter rework
    <ConnectionProvider endpoint={RPC_ENDPOINT}>
      <WalletProvider wallets={wallets} onError={onError} autoConnect={false}>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

// export const ContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
//   return <WalletContextProvider>{children}</WalletContextProvider>;
// };
