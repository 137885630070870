import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

export const AppBar = () => {
  return (
    <div className="navbar">
      <a href="https://magapunks.com" className="back-arrow" />
      <WalletMultiButton className="wallet-button" />
    </div>
  );
};
