import {
  generateSigner,
  transactionBuilder,
  publicKey,
  some,
  Umi,
  Transaction,
  KeypairSigner,
  signAllTransactions,
} from "@metaplex-foundation/umi";
import {
  CandyMachine,
  mintV2,
  safeFetchCandyGuard,
} from "@metaplex-foundation/mpl-candy-machine";
import { setComputeUnitLimit } from "@metaplex-foundation/mpl-toolbox";
import { base58 } from "@metaplex-foundation/umi/serializers";

const treasuryAddress = publicKey(
  "WHDGbfncWMLvcem8XWYLxWXinGz3YQK34sNcZV8aMye"
);

export async function mint(
  amount: number,
  candyMachine: CandyMachine,
  umi: Umi
) {
  // Fetch the Candy Guard.
  const candyGuard = await safeFetchCandyGuard(umi, candyMachine.mintAuthority);

  if (!candyGuard) {
    throw new Error("Invalid candy machine");
  }

  // Mint from the Candy Machine.
  const transactions: Transaction[] = [];
  const signers: KeypairSigner[] = [];

  const latestBlockhash = (await umi.rpc.getLatestBlockhash()).blockhash;

  // console.log("UMI RPC", umi.rpc.getEndpoint());
  // console.log("UMI Payer", umi.payer.publicKey);
  // console.log("UMI Identity", umi.identity.publicKey);

  for (let i = 0; i < amount; i++) {
    const nftMint = generateSigner(umi);

    signers.push(nftMint);

    transactions.push(
      transactionBuilder()
        .add(setComputeUnitLimit(umi, { units: 800_000 }))
        .add(
          mintV2(umi, {
            tokenStandard: candyMachine.tokenStandard,
            candyMachine: candyMachine.publicKey,
            candyGuard: candyGuard.publicKey,
            nftMint,
            collectionMint: candyMachine.collectionMint,
            collectionUpdateAuthority: candyMachine.authority,
            mintArgs: {
              solPayment: some({ destination: treasuryAddress }),
              mintLimit: some({ id: 1 }),
            },
          })
        )
        .setBlockhash(latestBlockhash)
        .build(umi)
    );
  }

  // console.log("Tx", transactions);
  // console.log("Signers", signers);

  const signedTransactions = await signAllTransactions(
    transactions.map((transaction, index) => {
      const signer = signers[index];

      return {
        transaction,
        signers: [umi.payer, signer],
      };
    })
  );

  // console.log("Signed Tx", signedTransactions);

  await Promise.allSettled(
    signedTransactions.map((tx, index) => {
      return umi.rpc.sendTransaction(tx).then((signature) => {
        console.log(
          `Transaction ${index + 1} resolved with signature: ${
            base58.deserialize(signature)[0]
          }`
        );
      });
      // .catch((error) => {
      //   console.error(`Transaction ${index + 1} failed:`, error);
      // });
    })
  );
}
