import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CandyMachine,
  fetchCandyMachine,
} from "@metaplex-foundation/mpl-candy-machine";
import { useWallet } from "@solana/wallet-adapter-react";
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
import { walletAdapterIdentity } from "@metaplex-foundation/umi-signer-wallet-adapters";
import { mplCandyMachine } from "@metaplex-foundation/mpl-candy-machine";
import { mplTokenMetadata } from "@metaplex-foundation/mpl-token-metadata";
import { CANDY_MACHINE_ID, RPC_ENDPOINT } from "./constants";

export function useCandyMachine() {
  // State
  const [candyMachine, setCandyMachine] = useState<CandyMachine | null>(null);

  // Vars
  const wallet = useWallet();

  const umi = useMemo(
    () =>
      createUmi(RPC_ENDPOINT)
        .use(mplTokenMetadata())
        .use(mplCandyMachine())
        .use(walletAdapterIdentity(wallet)),
    [wallet]
  );

  const fetch = useCallback(async () => {
    setCandyMachine(await fetchCandyMachine(umi, CANDY_MACHINE_ID));
  }, [umi]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    candyMachine,
    refetch: fetch,
    umi,
    wallet,
  };
}
