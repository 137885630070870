import { AppBar } from "./components";
import { ContextProvider } from "./contexts";
import { Mint } from "./mint";

function App() {
  return (
    <ContextProvider>
      <div className="app-container">
        <AppBar />
        <Mint />
      </div>
    </ContextProvider>
  );
}

export default App;
